import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-5">
      <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Privacy Policy / गोपनीयता नीति</h1>
        <p className="text-gray-600 mb-4">
          Welcome to our Privacy Policy page. We value your privacy and are committed to protecting your personal information.
          <br />
          हमारे गोपनीयता नीति पृष्ठ पर आपका स्वागत है। हम आपकी गोपनीयता की सुरक्षा के लिए प्रतिबद्ध हैं।
        </p>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-white mb-2">Information We Collect / जो जानकारी हम इकट्ठा करते हैं</h2>
          <p className="text-gray-600">
            We collect information that you provide when creating an account, earning points, or using our services. This includes:
            <br />
            हम आपके द्वारा प्रदान की गई जानकारी को इकट्ठा करते हैं जब आप खाता बनाते हैं, अंक अर्जित करते हैं, या हमारी सेवाओं का उपयोग करते हैं। इसमें शामिल है:
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>Personal details like name, email, and contact information.<br />व्यक्तिगत जानकारी जैसे नाम, ईमेल और संपर्क जानकारी।</li>
            <li>Activity history and point transactions.<br />गतिविधि इतिहास और अंक लेनदेन।</li>
            <li>Device and usage information.<br />डिवाइस और उपयोग जानकारी।</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-white mb-2">How We Use Your Information / हम आपकी जानकारी का उपयोग कैसे करते हैं</h2>
          <p className="text-gray-600">
            The data we collect is used to:
            <br />
            हमारी एकत्रित जानकारी का उपयोग निम्नलिखित के लिए किया जाता है:
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>Provide and improve our services.<br />हमारी सेवाओं को प्रदान और सुधारने के लिए।</li>
            <li>Ensure secure and enjoyable gameplay.<br />सुरक्षित और मनोरंजक गेमप्ले सुनिश्चित करने के लिए।</li>
            <li>Comply with legal and regulatory requirements.<br />कानूनी और नियामक आवश्यकताओं का पालन करने के लिए।</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-white mb-2">Data Security / डेटा सुरक्षा</h2>
          <p className="text-gray-600">
            We implement advanced security measures to protect your data. However, please note that no system is 100% secure.
            <br />
            हम आपके डेटा की सुरक्षा के लिए उन्नत सुरक्षा उपाय लागू करते हैं। हालांकि, कृपया ध्यान दें कि कोई भी प्रणाली 100% सुरक्षित नहीं हो सकती।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-white mb-2">Third-Party Sharing / तृतीय-पक्ष के साथ जानकारी साझा करना</h2>
          <p className="text-gray-600">
            Your information will not be shared with third parties unless required by law or necessary to fulfill service-related tasks, such as sending notifications or maintaining the platform.
            <br />
            आपकी जानकारी को तृतीय-पक्षों के साथ साझा नहीं किया जाएगा जब तक कि कानूनी रूप से आवश्यक न हो या सेवा से संबंधित कार्यों को पूरा करने के लिए आवश्यक न हो, जैसे कि सूचनाएं भेजना या प्लेटफ़ॉर्म को बनाए रखना।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-white mb-2">Changes to this Privacy Policy / गोपनीयता नीति में बदलाव</h2>
          <p className="text-gray-600">
            We may update this policy occasionally. We encourage you to review this page periodically for any changes.
            <br />
            हम समय-समय पर इस नीति को अपडेट कर सकते हैं। हम आपको किसी भी बदलाव की जानकारी के लिए इस पृष्ठ को नियमित रूप से जांचने की सलाह देते हैं।
          </p>
        </section>

        <div className="text-center">
          <button className="bg-blue-500 text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-600">
            Contact Us / हमसे संपर्क करें
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
