import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-5">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Terms and Conditions / नियम और शर्तें
        </h1>
        <p className="text-gray-600 mb-6 text-justify">
          Welcome to our Fun Gaming platform. By accessing or using our
          services, you agree to be bound by the terms and conditions outlined
          below. Please read them carefully before proceeding.
          <br />
          हमारे मजेदार गेमिंग प्लेटफ़ॉर्म में आपका स्वागत है। हमारी सेवाओं का
          उपयोग करके, आप नीचे दी गई नियम और शर्तों से बंधे होने के लिए सहमत होते
          हैं। कृपया आगे बढ़ने से पहले उन्हें ध्यान से पढ़ें।
        </p>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            1. Eligibility / पात्रता
          </h2>
          <p className="text-gray-600">
            You must be at least 18 years old or the age of majority in your
            jurisdiction to participate in our games. By using this platform,
            you confirm that you meet the eligibility criteria.
            <br />
            आपको हमारे खेलों में भाग लेने के लिए कम से कम 18 वर्ष का या अपने
            अधिकार क्षेत्र में वयस्कता की आयु का होना आवश्यक है। इस प्लेटफ़ॉर्म
            का उपयोग करके, आप पुष्टि करते हैं कि आप पात्रता मानदंड को पूरा करते
            हैं।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            2. Responsible Gaming / जिम्मेदार गेमिंग
          </h2>
          <p className="text-gray-600">
            We encourage responsible gaming. Please ensure you play within your
            limits and seek help if you feel your gaming habits are becoming
            problematic.
            <br />
            हम जिम्मेदार गेमिंग को प्रोत्साहित करते हैं। कृपया सुनिश्चित करें कि
            आप अपनी सीमाओं के भीतर खेलें और यदि आपको लगता है कि आपकी गेमिंग
            आदतें समस्याग्रस्त हो रही हैं तो मदद लें।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            3. Prohibited Activities / निषिद्ध गतिविधियाँ
          </h2>
          <p className="text-gray-600">
            Any fraudulent activities, collusion, or use of unauthorized
            software is strictly prohibited and may result in the termination of
            your account.
            <br />
            कोई भी धोखाधड़ी गतिविधियाँ, मिलीभगत, या अनधिकृत सॉफ़्टवेयर का उपयोग
            सख्ती से निषिद्ध है और आपके खाते की समाप्ति का कारण बन सकता है।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            4. Privacy and Data Protection / गोपनीयता और डेटा संरक्षण
          </h2>
          <p className="text-gray-600">
            Your personal information will be handled in accordance with our
            Privacy Policy. We are committed to protecting your data.
            <br />
            आपकी व्यक्तिगत जानकारी को हमारी गोपनीयता नीति के अनुसार प्रबंधित
            किया जाएगा। हम आपके डेटा की सुरक्षा के लिए प्रतिबद्ध हैं।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            5. Security and Account Responsibility / सुरक्षा और खाता जिम्मेदारी
          </h2>
          <p className="text-gray-600">
            In the event your account is hacked, we are not responsible for any
            losses incurred. Additionally, if you attempt to hack the platform,
            you will forfeit any points and may face account termination.
            <br />
            यदि आपका खाता हैक हो जाता है, तो हुए किसी भी नुकसान के लिए हम
            जिम्मेदार नहीं होंगे। इसके अलावा, यदि आप प्लेटफ़ॉर्म को हैक करने का
            प्रयास करते हैं, तो आप किसी भी अर्जित अंकों को खो देंगे और आपके खाते
            की समाप्ति हो सकती है।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            6. Changes to Terms / नियमों में बदलाव
          </h2>
          <p className="text-gray-600">
            We reserve the right to update these terms at any time. It is your
            responsibility to review them regularly to stay informed of any
            changes.
            <br />
            हम किसी भी समय इन नियमों को अपडेट करने का अधिकार सुरक्षित रखते हैं।
            किसी भी बदलाव की जानकारी रखने के लिए उन्हें नियमित रूप से समीक्षा
            करना आपकी जिम्मेदारी है।
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-lg font-semibold text-white mb-2">
            7. Point Rules
          </h2>
          <p className="text-gray-600">1 Point = 1 Indian Rupee</p>
          <p className="text-gray-600 mt-2">
            Points are accumulated through eligible actions, such as purchases,
            completing challenges, or engaging with specific activities. The
            total points earned can be redeemed for various rewards or discounts
            within the platform. The conversion rate between points and rupees
            is fixed at a 1:1 ratio. Points have an expiration period of 12
            months, after which unused points will be forfeited.
          </p>
          <p className="text-gray-600 mt-2">
            Note: Points cannot be transferred between accounts and are
            non-refundable. Please refer to our terms and conditions for more
            details on point usage and rewards.
          </p>
        </section>

        <div className="text-center mt-8">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded">
            Accept and Proceed / स्वीकार करें और आगे बढ़ें
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
