import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
import CountdownPopup from "../components/CountdownPopup";
const Bidding4 = () => {
  const { tableId } = useParams();
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState(false); // Track if the time is expired

  const [timeLeft, setTimeLeft] = useState(null);
  const [tableDetails, setTableDetails] = useState(null); // State to store table details
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [betAmount, setBetAmount] = useState("");
  const [recentResults, setRecentResults] = useState([]); // State to store recent results
  const [userData, setUserData] = useState(null); // User data from decoded token
  const [error, setError] = useState(null);
  const biddingScheme = "1mins"; // Constant bidding scheme
  const categories = [
    {
      name: "Animals (जानवर)",
      image:
        "https://ik.imagekit.io/devhm/ceba38ca-f3b6-4669-a3dd-f0d265080ca9.jpg?updatedAt=1733775627069", // Replace with actual image URL
    },
    {
      name: "Plants (पौधे)",
      image:
        "https://ik.imagekit.io/devhm/dcb6f9f7-762a-4919-9ad0-bd4fab8ea820.jpg?updatedAt=1733775627233", // Replace with actual image URL
    },
    {
      name: "Numbers (संख्या)",
      image:
        "https://ik.imagekit.io/devhm/e9b6b7c4-fbdb-4d89-b9f5-a976971925af.jpg?updatedAt=1733775627144", // Replace with actual image URL
    },
    {
      name: "Alphabets (अक्षर)",
      image:
        "https://ik.imagekit.io/devhm/9cb45997-b6a5-4afc-b6f3-cd8e2db2fd0a.jpg?updatedAt=1733775627127", // Replace with actual image URL
    },
  ];

  const categoryMapping = {
    "Animals (जानवर)": 1,
    "Plants (पौधे)": 2,
    "Numbers (संख्या)": 3,
    "Alphabets (अक्षर)": 4,
  };

  useEffect(() => {
    const fetchRecentResults = async () => {
      try {
        const response = await fetch(
          `https://api.investdpaisa.com/api/bidTables/results?biddingScheme=${biddingScheme}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch recent results for table ID: ${biddingScheme}`);
        }
        const data = await response.json();
        console.log(data.bidTables);
        setRecentResults(data.bidTables);
      } catch (err) {
        setError(err.message);
      }
    };
  
    fetchRecentResults(); // Call function to fetch results
    if (isExpired) {
      fetchRecentResults(); // Fetch results when time is expired
    }
  
  
  }, [isExpired]); // Empty dependency array ensures this runs only once when the component mounts
  

  // Fetch user data from the token
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not logged in. Please log in.");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.userId) {
        // Fetch user details using the decoded userId
        const fetchUserData = async () => {
          const response = await axios.get(
            `https://api.investdpaisa.com/api/users/${decodedToken.userId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setUserData(response.data);
        };
        fetchUserData();
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }, []);

  // Fetch table details based on tableId
  useEffect(() => {
    const fetchTableDetails = async () => {
      try {
        if (!tableId) {
          throw new Error("Table ID is missing in the query parameters.");
        }
        const response = await fetch(
          `https://api.investdpaisa.com/api/bidTables/${tableId}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch details for table ID: ${tableId}`);
        }
        const data = await response.json();
        setTableDetails(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchTableDetails();
  }, [tableId]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setError(null);
  };
 
  const handlePlaceBet = async () => {
    if (!selectedCategory) {
      setError(
        "कृपया पहले एक श्रेणी चुनें। (Please select a category before placing a bet.)"
      );
      return;
    }
    if (!betAmount || isNaN(betAmount) || betAmount <= 0) {
      setError(
        "कृपया एक मान्य दांव राशि दर्ज करें। (Please enter a valid bet amount.)"
      );
      return;
    }

    if (!userData) {
      setError("User data not found. Please log in again.");
      return;
    }

    const betValue = parseFloat(betAmount);
    const winAmount = betValue * 2.5; // Calculate win amount
    const updatedWalletBalance = userData.walletBalance - betValue;

    if (updatedWalletBalance < 0) {
      setError("Insufficient balance to place the bet.");
      return;
    }

    try {
      // Place the bet
      const response = await axios.post(
        `https://api.investdpaisa.com/api/bidtables/${tableId}/bid`,
        {
          bidderId: userData._id,
          number: categoryMapping[selectedCategory],
          winAmount,
          bidAmount: betValue,
        }
      );
      // Generate a random order ID
      const orderId = `ORD-${Math.floor(
        Math.random() * 1000000
      )}-${Date.now()}`;
      // Create a new transaction record
      const newTransaction = {
        orderId,
        amount: betValue,
        type: "debit", // Debit transaction for placing a bid
        date: new Date().toISOString(),
        description: `You placed a bet on ${selectedCategory} of amount ${betValue} in bid table(${tableDetails}) at ${new Date().toLocaleTimeString()} and Your orderId is ${orderId}.`,
      };
      if (response.status === 200) {
        // Update user wallet
        const updatedUserData = {
          walletBalance: updatedWalletBalance,
          transactions: [...userData.transactions, newTransaction], // Add new transaction to existing history
        };

        await axios.put(
          `https://api.investdpaisa.com/api/users/${userData._id}`,
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        alert(`Bet placed successfully! Win Amount: ${winAmount}`);
        window.location.reload();
      }
    } catch (err) {
      console.error("Error placing bid:", err);
      setError("Failed to place the bid. Please try again later.");
    }
  };

  // Function to calculate time left
  const calculateTimeLeft = (expire) => {
    const now = new Date();
    now.setHours(now.getHours() + 5); // Adjust for timezone (if required)
    now.setMinutes(now.getMinutes() + 30); // Adjust for timezone (if required)
  
    const expiryDate = new Date(expire);
    const diff = expiryDate - now;
  
    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  
      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      setIsExpired(false); // Time has not expired
    } else {
      setTimeLeft("Expired");
      setIsExpired(true); // Time has expired
    
    }
  };
  
  

  // Set an interval to update the time left every second
  useEffect(() => {
    let timer;
    if (tableDetails?.expire) {
      timer = setInterval(() => {
        calculateTimeLeft(new Date(tableDetails.expire));
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [tableDetails]);
  
  return (
    <div className="p-4 pb-20">
      <h1 className="text-2xl font-bold text-center mb-2 text-yellow-300">Lucky Pick!</h1>
      <h2 className="text-xs font-semibold text-center my-2 text-white">
        कृपया कोई भी नंबर चुनें (Choose any number)
      </h2>
      <h2 className="text-center my-2 text-yellow-300">🔥Earn 2.5X Rewards on Every Play!🔥</h2>
      {!tableDetails ? (
        <p className="text-center text-gray-500">Loading table details...</p>
      ) : (
        <>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          {/* Display Table Details */}
          <div className="mb-4 text-center text-sm bg-gray-100 p-4 rounded-md shadow-md">
            <p className="text-red-500">
              <strong>Time Left:</strong> {timeLeft}
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-6">
            {categories.map((category) => (
              <div
                key={category.name}
                onClick={() => {
                  if (timeLeft !== "Expired") {
                    handleCategoryClick(category.name);
                  }
                }}
                className={`p-4 text-center rounded-md shadow-md transition border 
        ${
          selectedCategory === category.name
            ? "bg-blue-200 border-blue-400"
            : "bg-gray-100 border-gray-300"
        }
        ${
          timeLeft === "Expired"
            ? "cursor-not-allowed opacity-50"
            : "cursor-pointer"
        }
        hover:bg-blue-100`}
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className="mx-auto h-32 mb-2 rounded-lg"
                />
                <span className="text-lg font-medium">{category.name}</span>
              </div>
            ))}
          </div>

          <div>
            {timeLeft === "Expired" ? (
              <CountdownPopup
                result={recentResults[recentResults.length - 1]?.result}
              />
            ) : (
              // Replace this with your actual component
              <p></p> // Optional: replace with another component or content
            )}
          </div>
          <div className="mb-6 text-center">
            <input
              type="number"
              value={betAmount}
              onChange={(e) => setBetAmount(e.target.value)}
              placeholder="Enter your bet amount (अपनी दांव राशि दर्ज करें)"
              className="w-full max-w-sm p-2 border border-gray-300 rounded-md text-center"
            />
            <div className="flex justify-around gap-2 pt-4">
              {[50, 100, 500, 1000, 2000].map((betAmount) => (
                <button
                  key={betAmount}
                  onClick={() => setBetAmount(betAmount)}
                  className="px-4 py-2 bg-blue-700 text-white rounded-lg hover:bg-sky-600 transition"
                >
                  {betAmount}
                </button>
              ))}
            </div>
          </div>

          <div className="text-center">
            <button
              onClick={handlePlaceBet}
              className="px-6 py-2 bg-blue-700 text-white font-medium rounded-md shadow-md hover:bg-blue-600 transition"
            >
              Place Bet (दांव लगाएं)
            </button>
          </div>
        </>
      )}

      <div className="mt-8 bg-gray-200 p-4 rounded-md shadow-md">
        {/* Display most recent result */}
        {recentResults.length > 0 && (
          <div className="mb-4 bg-white p-4 rounded-md shadow-md">
            <p className="text-center text-lg font-semibold text-gray-800">
              <strong>Most Recent Result</strong>
            </p>
          </div>
        )}

        {/* Display last 10 results */}
        <div className="flex flex-wrap justify-center gap-4">
          {recentResults
            .slice() // Create a shallow copy to avoid modifying the original array
            .slice(0, 8) // Limit to the latest 10 results
            .map((result, index) => {
              // Parse JSON from result.result
              let winnerNumber = null;
              try {
                const parsedData = JSON.parse(result.result);
                winnerNumber = parsedData.winnerNumber;
              } catch (error) {
                console.error("Error parsing JSON:", error, result.result);
              }

              // Map winnerNumber to category name
              const categoryMapping = {
                "Animals (जानवर)": 1,
                "Plants (पौधे)": 2,
                "Numbers (संख्या)": 3,
                "Alphabets (अक्षर)": 4,
              };

              // Reverse the mapping to get the category name from winnerNumber
              const winnerCategory = Object.keys(categoryMapping).find(
                (key) => categoryMapping[key] === winnerNumber
              );

              return (
                <div
                  key={index}
                  className={`flex p-8 w-12 h-14 justify-center text-[10px] items-center rounded-full text-black bg-yellow-200 font-bold shadow-lg`}
                >
                  {winnerCategory || "N/A"}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Bidding4;
