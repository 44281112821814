import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Bidding1 from "./pages/Bidding1";
import Bidding2 from "./pages/Bidding2";
import BidOrder from "./pages/BidOrder";
import Wallet from "./pages/Wallet";
import Home from "./pages/Home";
import Account from "./pages/Account";
import PrivateRoute from "./components/PrivateRoute"; // import PrivateRoute
import Bidding3 from "./pages/Bidding3";
import ResetPassword from "./pages/ResetPassword";
import Bidding4 from "./pages/Bidding4";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermAndConditions";
import AddPayment from "./pages/AddPayment";
import CreateAccount from "./pages/CreateAccount";
import WithdrawFunds from "./pages/WithdrawFunds";
import AddFunds from "./pages/AddFunds";
import Games from "./pages/Games";
import Bidding5 from "./pages/Bidding5";
import ForgotPassword from "./pages/ForgotPassword";

const Layout = () => {
  return (
    <Router>
      <LayoutContent />
    </Router>
  );
};

const LayoutContent = () => {
  const location = useLocation();

  // Check if the current path is the login page, privacy policy, or terms and conditions
  const isExcludedPage = [
    "/login",
    "/privacypolicy",
    "/termsandconditions",
    "/createAccount",
    "/forgotpassword",
  ].includes(location.pathname);

  return (
    <div>
      {/* Conditionally render Navbar and Footer based on the current route */}
      {!isExcludedPage && <Navbar />}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/createAccount" element={<CreateAccount />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        {/* Use PrivateRoute for protected routes */}
        <Route path="/" element={<PrivateRoute element={Home} />} />
        <Route
          path="/bidding1/:tableId"
          element={<PrivateRoute element={Bidding1} />}
        />
        <Route
          path="/bidding2/:tableId"
          element={<PrivateRoute element={Bidding2} />}
        />
        <Route
          path="/bidding3/:tableId"
          element={<PrivateRoute element={Bidding3} />}
        />
        <Route
          path="/bidding4/:tableId"
          element={<PrivateRoute element={Bidding4} />}
        />
        <Route
          path="/bidding5/:tableId"
          element={<PrivateRoute element={Bidding5} />}
        />
        <Route
          path="/bid/:number/:biddingScheme/:bidTableId"
          element={<PrivateRoute element={BidOrder} />}
        />
        <Route path="/wallet" element={<PrivateRoute element={Wallet} />} />
        <Route path="/game" element={<PrivateRoute element={Games} />} />
        <Route path="/addFunds" element={<PrivateRoute element={AddFunds} />} />
        <Route
          path="/withdrawFunds"
          element={<PrivateRoute element={WithdrawFunds} />}
        />
        <Route path="/account" element={<PrivateRoute element={Account} />} />
        <Route
          path="/addpayment"
          element={<PrivateRoute element={AddPayment} />}
        />
        <Route
          path="/resetPassword"
          element={<PrivateRoute element={ResetPassword} />}
        />
      </Routes>

      {/* Conditionally render Footer based on the current route */}
      {!isExcludedPage && <Footer />}
    </div>
  );
};

export default Layout;
