import React from 'react'

const AddPayment = () => {
  const handleRefundRequest = () => {
    // Placeholder for refund logic
    alert('Refund request initiated');
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">Payment Details</h2>

      <div className="bg-gray-100 p-4 rounded-md shadow-md mb-6">
        <h3 className="text-xl font-semibold mb-2">Bank Details</h3>
        <p className="text-white">Bank Name: Example Bank</p>
        <p className="text-white">Account Number: 1234567890</p>
        <p className="text-white">IFSC Code: EXAM123456</p>
      </div>

      <button 
        onClick={handleRefundRequest} 
        className="w-full py-3 bg-red-500 text-white font-semibold rounded-md hover:bg-red-600 transition duration-200"
      >
        Request Payment Refund
      </button>
    </div>
  )
}

export default AddPayment
