import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import CountdownPopup from "../components/CountdownPopup";

const Bidding1 = () => {
  const { tableId } = useParams(); // Fetch the table ID from the URL parameters
  const navigate = useNavigate(); // Initialize the navigate function
  const [tableDetails, setTableDetails] = useState(null); // State to store table details
  const [timeLeft, setTimeLeft] = useState(null); // State to store time left
  const [error, setError] = useState(null); // State to handle errors
  const [userId, setUserId] = useState(null); // Store the current user's ID
  const [recentResults, setRecentResults] = useState([]); // State to store recent results
  const numbers = Array.from({ length: 10 }, (_, index) => index); // Array [0, 1, ..., 9]
  const [isExpired, setIsExpired] = useState(false); // Track if the time is expired
  const biddingScheme = "120mins"; // Constant bidding scheme
  useEffect(() => {
    const fetchRecentResults = async () => {
      try {
        const response = await fetch(
          `https://api.investdpaisa.com/api/bidTables/results?biddingScheme=${biddingScheme}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch recent results for table ID: ${biddingScheme}`
          );
        }
        const data = await response.json();
        console.log(data.bidTables);
        setRecentResults(data.bidTables);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchRecentResults(); // Call function to fetch results
    if (isExpired) {
      fetchRecentResults(); // Fetch results when time is expired
    }
  }, [isExpired]); // Empty dependency array ensures this runs only once when the component mounts
  // Decode token to fetch user ID
  // Decode token to fetch user ID
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (err) {
        console.error("Invalid token:", err);
      }
    }
  }, []);

  // Validate tableId and fetch table details
  useEffect(() => {
    const fetchTableDetails = async () => {
      try {
        if (!tableId) {
          throw new Error("Table ID is missing. Please check the URL.");
        }

        // Use Axios to fetch the data
        const response = await axios.get(
          `https://api.investdpaisa.com/api/bidTables/${tableId}`
        );
        setTableDetails(response.data);

        // Calculate initial time left
        calculateTimeLeft(new Date(response.data.expire));
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          err.message ||
          "An error occurred while fetching data.";
        setError(errorMessage);
      }
    };

    fetchTableDetails();
  }, [tableId]);

  // Function to calculate time left
  const calculateTimeLeft = (expire) => {
    const now = new Date();
    now.setHours(now.getHours() + 5);
    now.setMinutes(now.getMinutes() + 30);

    const expiryDate = new Date(expire);
    const diff = expiryDate - now;

    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    } else {
      setTimeLeft("Expired");
      setIsExpired(true); // Time has expired
    }
  };

  // Set an interval to update the time left every second
  useEffect(() => {
    let timer;
    if (tableDetails?.expire) {
      timer = setInterval(() => {
        calculateTimeLeft(new Date(tableDetails.expire));
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [tableDetails]);

  const handleClick = (number) => {
    // Navigate to the next page with selected number, tableId, and biddingScheme as parameters
    if (tableId) {
      navigate(`/bid/${number}/${biddingScheme}/${tableId}`);
    } else {
      setError("Cannot navigate: Table ID is missing.");
    }
  };

  const getBidDetails = (number) => {
    // Filter bids for the specific number
    const bidsForNumber =
      tableDetails?.bids?.filter(
        (bid) => bid.number === number && bid.bidderId === userId
      ) || [];
    const totalAmount = bidsForNumber.reduce(
      (sum, bid) => sum + bid.bidAmount,
      0
    );
    const bidCount = bidsForNumber.length;
    const bidAmounts = bidsForNumber.map(
      (bid, index) => `${bid.bidAmount} (Bid ${index + 1})`
    );

    return { totalAmount, bidCount, bidAmounts };
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold text-center text-yellow-300 mb-2">SELECT TO WIN !</h1>
      <h2 className="text-xs font-semibold text-center mb-4 text-white">
        कृपया कोई भी नंबर चुनें (Choose any number)
      </h2>
      <h2 className="text-center text-yellow-300 mb-6">🔥Earn 9X Rewards on Every Play!🔥</h2>

      {error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : !tableDetails ? (
        <p className="text-center text-gray-500">Loading table details...</p>
      ) : (
        <>
          {/* Display Table Details */}
          <div className="mb-4 text-sm text-center bg-gray-100 p-4 rounded-md shadow-md">
            <p>
              <strong>Start Time:</strong>{" "}
              {new Date(tableDetails.start).toLocaleString("en-US", {
                timeZone: "UTC",
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })}
            </p>
            <p>
              <strong>Expire Time:</strong>{" "}
              {new Date(tableDetails.expire).toLocaleString("en-US", {
                timeZone: "UTC",
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })}
            </p>
            <p className="text-red-500">
              <strong>Time Left:</strong> {timeLeft}
            </p>
          </div>

          {/* Display Number Options */}
          <ul className="grid grid-cols-5 gap-2">
            {numbers.map((number) => {
              const { totalAmount, bidCount, bidAmounts } =
                getBidDetails(number);
              const isExpired = timeLeft === "Expired";

              return (
                <li
                  key={number}
                  onClick={() => !isExpired && handleClick(number)}
                  className="bg-gray-100 border border-gray-300 rounded-md p-4 shadow-md cursor-pointer hover:bg-gray-200 transition"
                >
                  <div className="text-center text-lg font-medium">
                    {number}
                  </div>
                  {bidCount > 0 && (
                    <div className="text-xs text-green-500 mt-2">
                      <p>+{totalAmount}</p>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
      <div>
        {timeLeft === "Expired" ? (
          <CountdownPopup
            result={recentResults[recentResults.length - 1]?.result}
          />
        ) : (
          // Replace this with your actual component
          <p></p> // Optional: replace with another component or content
        )}
      </div>
      {/* Recent Results Section */}
      <div className="mt-8 bg-gray-200 p-4 rounded-md shadow-md">
        {/* Display most recent result */}
        {recentResults.length > 0 && (
          <div className="mb-4 bg-white p-4 rounded-md shadow-md">
            <p className="text-center text-lg font-semibold text-gray-800">
              <strong>Most Recent Result</strong>
            </p>
          </div>
        )}

        {/* Display last 10 results */}
        <div className="flex flex-wrap justify-center gap-4">
          {recentResults
            .slice()
            .slice(0, 10)
            .map((result, index) => {
              // Parse JSON from result.result
              let winnerNumber = null;
              try {
                const parsedData = JSON.parse(result.result);
                winnerNumber = parsedData.winnerNumber || "N/A";
              } catch (error) {
                console.error("Error parsing JSON:", error, result.result);
                winnerNumber = "N/A";
              }

              return (
                <div
                  key={index}
                  className={`w-12 h-12 flex justify-center items-center bg-red-200 text-black font-bold shadow-lg rounded-full`}
                >
                  {winnerNumber}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Bidding1;
