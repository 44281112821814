import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AddFunds = () => {
  const [paymentAmount, setPaymentAmount] = useState("");
  const [upiId, setUpiId] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token
      const decodedToken = jwtDecode(token);
      const { userId } = decodedToken;
      setUserId(userId);

      // Fetch user details using the userId
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(
            `https://api.investdpaisa.com/api/users/${userId}`
          );
          const data = await response.json();
          console.log(data);
          setUserData(data);
          setUpiId(data.upiId);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };

      fetchUserDetails();
    }
  }, []);

  const handleAddFunds = async () => {
    // Validate payment amount
    if (!paymentAmount || paymentAmount <= 0) {
      alert("Please enter a valid payment amount");
      return;
    }

    setLoading(true); // Start loading

    try {
      // Prepare the data to be sent
      const payload = {
        username: userData.username, // Include user's name
        mobileNumber: userData.mobileNumber, // Include user's mobile number
        upiId: userData.upiId, // Include UPI ID
        paymentAmount, // Include payment amount
        walletBalance: userData.walletBalance, // Include wallet balance
      };

      // Send the data to the API
      const response = await fetch(
        "https://api.investdpaisa.com/api/notify/addFunds",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the response is successful
      if (response.ok) {
        alert("Funds added successfully and admin notified");
      } else {
        const errorData = await response.json();
        alert("Error: " + (errorData.message || "Failed to notify admin"));
      }
    } catch (error) {
      // Handle errors
      alert("Error: " + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-customgrey px-2 my-10 mb-20">
      <div className="w-full  bg-customgrey text-white">
        <h2 className="text-3xl font-semibold text-yellow-300 mb-6 text-center">
          Add Funds
        </h2>

        <div className="bg-gray-100 p-4 rounded-md shadow-md mb-6">
          <img
            src="https://ik.imagekit.io/7uve7qsipm/WhatsApp%20Image%202024-12-11%20at%205.29.06%20PM.jpeg?updatedAt=1733959006481"
            alt="QR Code"
            className="w-full mb-4 rounded-lg"
          />
          <p className="text-white text-sm mb-2">
            After completing the payment, enter the amount you have paid.
          </p>
          <p className="text-red-500 text-sm mb-4">
            Disclaimer: We are not responsible for any payments sent to the
            wrong account. Please ensure the account details are correct before
            making any transactions.
          </p>
        </div>

        <input
          type="number"
          placeholder="Enter payment amount"
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          className="w-full py-2 px-4 rounded-lg border border-gray-600 bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300 mb-4"
          disabled={loading} // Disable input during loading
        />
        <p className="text-lg text-gray-300 mb-4">
          Your UPI ID: <span className="text-yellow-300">{upiId}</span>
        </p>

        <button
          onClick={handleAddFunds}
          className={`w-full py-3 text-white font-bold rounded-lg transition duration-200 ${
            loading ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"
          }`}
          disabled={loading} // Disable button during loading
        >
          {loading ? "Processing..." : "Payment Done"}
        </button>
      </div>
    </div>
  );
};

export default AddFunds;
