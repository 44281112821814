import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const WithdrawFunds = () => {
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [upiId, setUpiId] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token
      const decodedToken = jwtDecode(token);
      const { userId } = decodedToken;
      setUserId(userId);

      // Fetch user details using the userId
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(
            `https://api.investdpaisa.com/api/users/${userId}`
          );
          const data = await response.json();
          setUserData(data);
          setUpiId(data.upiId);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };

      fetchUserDetails();
    }
  }, []);

  const handleWithdrawFunds = async () => {
    // Validate payment amount
    if (!withdrawAmount || withdrawAmount <= 0) {
      alert("Please enter a valid payment amount");
      return;
    }

    setLoading(true); // Start loading

    try {
      // Prepare the data to be sent
      const payload = {
        username: userData.username,
        mobileNumber: userData.mobileNumber,
        upiId: userData.upiId,
        withdrawalAmount: withdrawAmount,
        walletBalance: userData.walletBalance,
      };

      // Send the data to the API
      const response = await fetch(
        "https://api.investdpaisa.com/api/notify/withdrawFunds",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the response is successful
      if (response.ok) {
        alert("Funds withdrawn successfully and admin notified");
      } else {
        const errorData = await response.json();
        alert("Error: " + (errorData.message || "Failed to notify admin"));
      }
    } catch (error) {
      // Handle errors
      alert("Error: " + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-customgrey shadow-md rounded-md">
      <div className="bg-gray-100 p-4 rounded-md shadow-md mb-6">
        <h3 className="text-xl font-semibold mb-2">Enter Withdrawal Amount</h3>
        <input
          type="number"
          placeholder="Enter amount to withdraw"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          className="w-full py-2 px-4 rounded-md border border-gray-300 mb-4"
          disabled={loading} // Disable input during loading
        />
        <p className="text-white my-4">Your UPI ID: {upiId}</p>
        <button
          onClick={handleWithdrawFunds}
          className={`w-full py-3 text-white font-semibold rounded-md transition duration-200 ${
            loading ? "bg-gray-400" : "bg-red-500 hover:bg-red-600"
          }`}
          disabled={loading} // Disable button during loading
        >
          {loading ? "Processing..." : "Request Payment Withdrawal"}
        </button>
        <p className="text-sm text-gray-500 mt-4">
          Please kindly wait for 3 hours after your request for the withdrawal
          to be processed.
        </p>
      </div>
    </div>
  );
};

export default WithdrawFunds;
