import React, { useState, useEffect } from 'react';

const CountdownPopup = ({ result }) => {
  const [countdown, setCountdown] = useState(5);
  const [showPopup, setShowPopup] = useState(false);
  const [showResult, setShowResult] = useState(false);

  // Parse the result string to extract winnerNumber
  const parseResult = (resultString) => {
    try {
      const resultObject = JSON.parse(resultString);
      return resultObject.winnerNumber;
    } catch (error) {
      console.error("Error parsing result:", error);
      return null;
    }
  };

  // Automatically start countdown when the component mounts
  useEffect(() => {
    setShowPopup(true); // Show the countdown popup as soon as the component mounts
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer); // Stop the timer when countdown reaches 0
          setShowPopup(false); // Hide countdown popup
          setShowResult(true); // Show result after countdown ends
          return 0;
        }
        return prev - 1; // Decrement countdown
      });
    }, 1000); // Decrease the countdown every second

    // Cleanup the timer when the component is unmounted or countdown ends
    return () => clearInterval(timer);
  }, []); // Empty dependency array to run only once on mount

  // Extract the winner number if result is a string
  const winnerNumber = typeof result === 'string' ? parseResult(result) : result?.winnerNumber;

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100">
      {/* Countdown Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-xl">
            <p className="text-4xl font-bold text-center">{countdown}</p>
          </div>
        </div>
      )}

      {/* Result Popup */}
      {showResult && winnerNumber !== undefined && winnerNumber !== null && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-green-500 p-8 rounded-lg shadow-xl">
            <p className="text-xs font-bold text-center text-white">
              Winner Number: {winnerNumber}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountdownPopup;
