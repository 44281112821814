import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import CountdownPopup from "../components/CountdownPopup";

const Bidding3 = () => {
  const { tableId } = useParams(); // Extract tableId from URL
  const navigate = useNavigate(); // Initialize the navigate function
  const [tableDetails, setTableDetails] = useState(null); // State to store table details
  const [recentResults, setRecentResults] = useState([]); // State to store recent results
  const [error, setError] = useState(null); // State to handle errors
  const numbers = Array.from({ length: 6 }, (_, index) => index + 1); // Array [1, 2, ..., 6]
  const [timeLeft, setTimeLeft] = useState(null);
  const [userId, setUserId] = useState(null);
  const biddingScheme = "2mins"; // Constant bidding scheme
  const [isExpired, setIsExpired] = useState(false); // Track if the time is expired
  // Dice colors corresponding to numbers
  const diceColors = [
    "bg-teal-400", // Number 1
    "bg-blue-600", // Number 2
    "bg-green-500", // Number 3
    "bg-yellow-500", // Number 4
    "bg-purple-800", // Number 5
    "bg-pink-500", // Number 6
  ];
  useEffect(() => {
    const fetchRecentResults = async () => {
      try {
        const response = await fetch(
          `https://api.investdpaisa.com/api/bidTables/results?biddingScheme=${biddingScheme}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch recent results for table ID: ${biddingScheme}`
          );
        }
        const data = await response.json();
        console.log(data.bidTables);
        setRecentResults(data.bidTables);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchRecentResults(); // Call function to fetch results
    if (isExpired) {
      fetchRecentResults(); // Fetch results when time is expired
    }
  }, [isExpired]); // Empty dependency array ensures this runs only once when the component mounts
  // Decode token to fetch user ID
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (err) {
        console.error("Invalid token:", err);
      }
    }
  }, []);

  // Fetch table details and recent results based on tableId
  useEffect(() => {
    const fetchTableDetails = async () => {
      try {
        if (!tableId) {
          throw new Error("Table ID is missing in the query parameters.");
        }
        const response = await fetch(
          `https://api.investdpaisa.com/api/bidTables/${tableId}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch details for table ID: ${tableId}`);
        }
        const data = await response.json();
        setTableDetails(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchTableDetails();
  }, [tableId]);

  const handleClick = (number) => {
    // Navigate to the next page with selected number, tableId, and biddingScheme as parameters
    navigate(`/bid/${number}/${biddingScheme}/${tableId}`);
  };

  const getBidDetails = (number) => {
    // Filter bids for the specific number and current user
    const bidsForNumber =
      tableDetails?.bids?.filter(
        (bid) => bid.number === number && bid.bidderId === userId
      ) || [];
    const totalAmount = bidsForNumber.reduce(
      (sum, bid) => sum + bid.bidAmount,
      0
    );
    const bidCount = bidsForNumber.length;

    return { totalAmount, bidCount };
  };

  // Function to calculate time left
  const calculateTimeLeft = (expire) => {
    const now = new Date();
    now.setHours(now.getHours() + 5);
    now.setMinutes(now.getMinutes() + 30);

    const expiryDate = new Date(expire);
    const diff = expiryDate - now;

    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    } else {
      setTimeLeft("Expired");
      setIsExpired(true); // Time has expired
    }
  };

  // Set an interval to update the time left every second
  useEffect(() => {
    let timer;
    if (tableDetails?.expire) {
      timer = setInterval(() => {
        calculateTimeLeft(new Date(tableDetails.expire));
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [tableDetails]);

  const renderDots = (number) => {
    const dots = [];
    for (let i = 0; i < number; i++) {
      dots.push(
        <div key={i} className="w-3 h-3 rounded-full bg-white mx-1"></div>
      );
    }
    return dots;
  };

  return (
    <div className="p-6 pb-20 ">
      <h1 className="text-2xl font-bold text-center mb-2 text-yellow-300">Roll Your Luck!</h1>
      <h2 className="text-xs font-semibold text-center my-2 text-white">
        कृपया कोई भी नंबर चुनें (Choose any number)
      </h2>
      <h2 className="text-center my-2 text-yellow-300 ">🔥Earn 3X Rewards on Every Play!🔥</h2>

      {error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : !tableDetails ? (
        <p className="text-center text-gray-500">Loading table details...</p>
      ) : (
        <>
          {/* Display Table Details */}
          <div className="mb-4 text-xs text-center bg-gray-100 p-4 mt-4 rounded-md shadow-md">
            <p className="text-red-500">
              <strong>Time Left:</strong> {timeLeft}
            </p>
          </div>

          {/* Display Number Options */}
          <div className="grid grid-cols-3 pl-3 gap-4">
            {/* Numbers 1, 2, 3 in first row */}
            {numbers.slice(0, 3).map((number, index) => {
              const { totalAmount, bidCount } = getBidDetails(number);
              const isExpired = timeLeft === "Expired"; // Check if the time is expired

              return (
                <div
                  key={number}
                  onClick={() => !isExpired && handleClick(number)} // Trigger navigation only if time is not expired
                  className={`cursor-pointer text-white font-bold flex flex-col justify-center items-center ${
                    diceColors[index]
                  } w-24 h-24 rounded-lg shadow-xl hover:scale-105 transition-transform duration-300 ${
                    isExpired ? "bg-gray-300 cursor-not-allowed" : ""
                  }`}
                >
                  {/* Render dots for numbers */}
                  <div className="flex justify-center items-center mb-2">
                    {renderDots(number)}
                  </div>
                  {bidCount > 0 && (
                    <div className="text-sm mt-2">
                      <p>+{totalAmount}</p>
                    </div>
                  )}
                </div>
              );
            })}

            {/* Numbers 4, 5, 6 in second row (Separate column for them) */}
            {numbers.slice(3, 6).map((number, index) => {
              const { totalAmount, bidCount } = getBidDetails(number);
              const isExpired = timeLeft === "Expired"; // Check if the time is expired

              return (
                <div
                  key={number}
                  onClick={() => !isExpired && handleClick(number)} // Trigger navigation only if time is not expired
                  className={`cursor-pointer text-white font-bold flex flex-col justify-center items-center ${
                    diceColors[index + 3]
                  } w-24 h-24 rounded-lg shadow-xl hover:scale-105 transition-transform duration-300 ${
                    isExpired ? "bg-gray-300 cursor-not-allowed" : ""
                  }`}
                >
                  {/* Render dots for numbers */}
                  <div className="grid justify-center gap-2 grid-cols-2 items-center mb-2">
                    {renderDots(number)}
                  </div>
                  {bidCount > 0 && (
                    <div className="text-sm mt-2">
                      <p>+{totalAmount}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div>
            {timeLeft === "Expired" ? (
              <CountdownPopup
                result={recentResults[recentResults.length - 1]?.result}
              />
            ) : (
              // Replace this with your actual component
              <p></p> // Optional: replace with another component or content
            )}
          </div>
          {/* Recent Results Section */}
          <div className="mt-8 bg-gray-200 p-4 rounded-md shadow-md">
            {/* Display most recent result */}
            {recentResults.length > 0 && (
              <div className="mb-4 bg-white p-4 rounded-md shadow-md">
                <p className="text-center text-lg font-semibold text-gray-800">
                  <strong>Most Recent Result</strong>
                </p>
              </div>
            )}

            {/* Display last 10 results */}
            <div className="flex flex-wrap justify-center gap-4">
              {recentResults
                .slice() // Create a shallow copy to avoid modifying the original array
                .slice(0, 10) // Limit to the latest 10 results
                .map((result, index) => {
                  // Parse JSON from result.result
                  let winnerNumber = null;
                  try {
                    const parsedData = JSON.parse(result.result);
                    winnerNumber = parsedData.winnerNumber;
                  } catch (error) {
                    console.error("Error parsing JSON:", error, result.result);
                  }

                  return (
                    <div
                      key={index}
                      className={`w-12 h-12 flex justify-center items-center ${
                        diceColors[result.winnerNumber - 1]
                      } rounded-full text-black bg-red-200 font-bold shadow-lg`}
                    >
                      {winnerNumber !== null ? winnerNumber : "N/A"}
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Bidding3;
