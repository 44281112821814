import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode

const BidOrder = () => {
  const { number, biddingScheme, bidTableId } = useParams(); // Extract number, biddingScheme, and bidTableId from the URL parameters
  const [bidAmount, setBidAmount] = useState(""); // State to track the input value
  const [userData, setUserData] = useState(null); // State for user data
  const [bidTable, setBidTable] = useState(null); // State for bid table data
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [loading, setLoading] = useState(true); // Loading state for fetching user and bid table data
  const navigate = useNavigate();
  const minBidAmount = 10; // Minimum bid amount in rupees
  const maxBidAmount = 500000; // Maximum bid amount in rupees
  const [timeLeft, setTimeLeft] = useState(null);
   const [isExpired, setIsExpired] = useState(false); // Track if the time is expired
  // Fetch user data and bid table data
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token"); // Get token from localStorage
      if (!token) {
        setErrorMessage("No token found, please log in. कृपया लॉगिन करें");
        setLoading(false);
        return;
      }

      try {
        // Decode the token
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.userId) {
          const userId = decodedToken.userId; // Get userId from decoded token
          const response = await axios.get(
            `https://api.investdpaisa.com/api/users/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Pass token in the request header
              },
            }
          );
          setUserData(response.data); // Store user data
        } else {
          setErrorMessage("Invalid token structure. अमान्य टोकन संरचना");
        }
      } catch (error) {
        setErrorMessage("Failed to decode the token. टोकन डिकोड करने में विफल");
        console.error(error);
      }
    };

    const fetchBidTableData = async () => {
      try {
        const response = await axios.get(
          `https://api.investdpaisa.com/api/bidTables/${bidTableId}`
        );
        setBidTable(response.data); // Store bid table data
      } catch (error) {
        setErrorMessage(
          "Failed to fetch bid table. बोली तालिका प्राप्त करने में विफल"
        );
        console.error(error);
      }
      setLoading(false); // Done loading, either with data or error
    };

    fetchUserData();
    fetchBidTableData();
  }, [bidTableId]); // Re-run when bidTableId changes
  const calculateTimeLeft = (expire) => {
    const now = new Date();
    now.setHours(now.getHours() + 5);
    now.setMinutes(now.getMinutes() + 30);

    const expiryDate = new Date(expire);
    const diff = expiryDate - now;

    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    } else {
      setTimeLeft("Expired");
      setIsExpired(true); // Time has expired
    }
  };

  // Set an interval to update the time left every second
  useEffect(() => {
    let timer;
    if (bidTable?.expire) {
      timer = setInterval(() => {
        calculateTimeLeft(new Date(bidTable.expire));
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [bidTable]);
  const handlePlaceOrder = async () => {
    if (!userData || !bidTable) return;

    const walletBalance = userData.walletBalance; // Extract wallet balance from user data
    const bidValue = parseFloat(bidAmount);

    // Check if the bid amount is valid and within the balance range
    if (isNaN(bidValue) || bidValue < minBidAmount) {
      setErrorMessage(`Bid amount should be at least ${minBidAmount}.`);
    } else if (bidValue > maxBidAmount) {
      setErrorMessage(`Bid amount should not exceed ${maxBidAmount}.`);
    } else if (bidValue <= walletBalance) {
      setErrorMessage(""); // Clear any previous error messages

      // Calculate win amount based on biddingScheme
      let winAmount = 0;
      if (biddingScheme === "12hrs") {
        winAmount = bidValue * 90; // 90 times the bid amount
      } else if (biddingScheme === "120mins") {
        winAmount = bidValue * 9; // 9 times the bid amount
      } else if (biddingScheme === "2mins") {
        winAmount = bidValue * 3; // 3 times the bid amount
      } else {
        setErrorMessage("Invalid bidding scheme.");
        return;
      }

      // Generate a random order ID
      const orderId = `ORD-${Math.floor(
        Math.random() * 1000000
      )}-${Date.now()}`;

      // Subtract the bid amount from wallet balance
      const updatedBalance = walletBalance - bidValue;

      // Create a new transaction record
      const newTransaction = {
        orderId,
        amount: bidValue,
        type: "debit", // Debit transaction for placing a bid
        date: new Date().toISOString(),
        description: `You placed a bet on number ${number} of amount ${bidValue} in bid table(${
          bidTable.tableName
        }) at ${new Date().toLocaleTimeString()} and Your orderId is ${orderId}.`,
      };

      // Update user data with the new wallet balance and transaction history
      const updatedUserData = {
        walletBalance: updatedBalance,
        transactions: [...userData.transactions, newTransaction], // Add new transaction to existing history
      };

      // Optionally send updated user data to the backend
      try {
        // Update user data with the new balance and transaction
        await axios.put(
          `https://api.investdpaisa.com/api/users/${userData._id}`,
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Create the bid after updating user data
        const bidData = {
          bidId: orderId, // Use the orderId as the bidId
          userId: userData._id, // Use the current user's ID
          bidAmount: bidValue,
          number,
          biddingScheme, // Fetch the bidding scheme from the URL params
          winAmount, // Include the calculated win amount
          time: new Date().toLocaleTimeString(), // Current time
        };

        // Update the bid table with the new bid
        const bidTableUpdate = {
          bidderId: userData._id,
          bidAmount: bidValue, // Ensure bidValue is defined and holds the correct bid amount
          winAmount,
          number,
        };

        // Send a POST request to update the bid table
        await axios.post(
          `https://api.investdpaisa.com/api/bidTables/${bidTableId}/bid`,
          bidTableUpdate,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Show a success message with the order details
        alert(
          `Bid placed successfully!\nOrder ID: ${orderId}\nAmount: ${bidAmount}\nWin Amount: ${winAmount}`
        );
        setUserData(updatedUserData); // Update the state with the new user data
        navigate(-1);
        setTimeout(() => {
          window.location.reload();
        }, 100); // Allows the browser to process the navigation first
      } catch (error) {
        setErrorMessage(
          "Failed to update wallet balance or place the bid. कृपया पुनः प्रयास करें"
        );
        console.error(error);
      }
    } else {
      setErrorMessage("Insufficient balance to place the bid.");
    }
  };

  if (loading) {
    return (
      <div className="text-center py-20 text-yellow-300 bg-gray-900">
        Loading user and bid table data... डेटा लोड हो रहा है...
      </div>
    );
  }

  return (
    <div className="pt-5 bg-gradient-to-br from-gray-900 via-customgrey h-screen mt-[-150px] to-gray-900 text-yellow-300 mb-20 flex items-center justify-center px-4">
      <div className="bg-customgrey shadow-lg rounded-lg p-8 max-w-md w-full">
      <div className="mb-4 text-center text-sm bg-customgrey p-4 rounded-md ">
            <p className="text-red-500 text-xl">
              <strong>Time Left:</strong> {timeLeft}
            </p>
          </div>
        <p className="text-lg text-center mb-4">
          <strong className="text-yellow-500 text-8xl"> {number}</strong>
        </p>

        {/* Display Error Message */}
        {errorMessage && (
          <p className="text-red-500 text-center mb-4">{errorMessage}</p>
        )}

        <div className="flex flex-col gap-4">
          <input
            type="number"
            placeholder="Enter your order amount"
            value={bidAmount}
            onChange={(e) => setBidAmount(e.target.value)}
            className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-yellow-300 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition"
            min={minBidAmount}
            max={maxBidAmount}
          />

          {/* Default Bid Buttons */}
          <div className="flex justify-around gap-2">
            {[50, 100, 500, 1000].map((amount) => (
              <button
                key={amount}
                onClick={() => setBidAmount(amount)}
                className="px-4 py-2 bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-600 transition"
              >
                {amount}
              </button>
            ))}
          </div>

          <button
            onClick={handlePlaceOrder}
            className="w-full bg-yellow-600 text-white font-semibold py-3 rounded-lg hover:bg-yellow-700 transition"
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default BidOrder;
