import React, { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [newPassword, setNewPassword] = useState(""); // New password state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(""); // For handling error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(""); // Reset error message

    try {
      // Send a POST request to reset password
      const response = await fetch(
        "https://api.investdpaisa.com/api/users/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            securityAnswer,
            newPassword,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong.");
      }

      alert("Password reset successful!");
      // Optionally, redirect to login page or clear form fields
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-4 bg-gradient-to-br from-gray-900 via-customgrey to-gray-900">
      <div className="bg-customgrey shadow-2xl rounded-lg p-8 max-w-md w-full text-white">
        {/* App Logo and Name */}
        <div className="flex flex-row justify-center items-center space-x-2">
          <div className="flex flex-col items-center">
            <h1
              className="text-6xl font-bold text-yellow-300 m-0 p-0"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
            >
              SAM
            </h1>
            <p
              className="text-4xl text-white m-0 p-0 -mt-1"
              style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}
            >
              GAMES
            </p>
          </div>
        </div>

        {/* Forgot Password Form */}
        <h2 className="text-2xl font-bold text-center text-yellow-300 my-6">
          Forgot Your Password?
        </h2>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div>
            <label
              htmlFor="username"
              className="block text-sm text-gray-300 mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>
          <div>
            <label
              htmlFor="securityQuestion"
              className="block text-sm text-gray-300 mb-2"
            >
              Security Question: What is your pet's name?
            </label>
            <input
              type="text"
              id="securityAnswer"
              value={securityAnswer}
              onChange={(e) => setSecurityAnswer(e.target.value)}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>
          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm text-gray-300 mb-2"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}{" "}
          {/* Show error message */}
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full py-3 rounded-lg font-bold text-lg tracking-wide transition ${
              isSubmitting
                ? "bg-gray-600 cursor-not-allowed text-gray-400"
                : "bg-yellow-300 hover:bg-yellow-400 text-black"
            }`}
          >
            {isSubmitting ? "Submitting..." : "Reset Password"}
          </button>
        </form>

        <p className="text-center text-gray-400 text-sm mt-4">
          Remember your password?{" "}
          <Link to="/login">
            <span className="text-yellow-300 font-bold hover:underline">
              Log in here
            </span>
          </Link>
        </p>

        <p className="text-center text-gray-400 text-sm mt-4">
          If you're having trouble, contact{" "}
          <a href="mailto:care.samgames@gmail.com">
            <span className="text-yellow-300 font-bold hover:underline">
              care.samgames@gmail.com
            </span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
