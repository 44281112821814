import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [dateTime, setDateTime] = useState({
    date: "",
    time: "",
    day: "",
    dayHindi: "",
  });

  const [bidTables, setBidTables] = useState([]);
  const [results, setResults] = useState({
    "120mins": [],
    "2mins": [],
    "12hrs": [],
    "1mins": [],
    "1minsCoin": [],
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Update DateTime
    const updateDateTime = () => {
      const now = new Date();
      const daysEnglish = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const daysHindi = [
        "रविवार",
        "सोमवार",
        "मंगलवार",
        "बुधवार",
        "गुरुवार",
        "शुक्रवार",
        "शनिवार",
      ];

      setDateTime({
        date: now.toLocaleDateString(),
        time: now.toLocaleTimeString(),
        day: daysEnglish[now.getDay()],
        dayHindi: daysHindi[now.getDay()],
      });
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Fetch bid tables
    const fetchBidTables = async () => {
      try {
        const response = await fetch("https://api.investdpaisa.com/api/bidTables/");
        if (!response.ok) {
          setMessage("Failed to fetch bid tables");
          return;
        }
        const data = await response.json();
        setBidTables(data);
      } catch (err) {
        setMessage("Error fetching bid tables: " + err.message);
      }
    };

    fetchBidTables();

    // Fetch results for each bidding scheme
    const fetchResults = async (scheme) => {
      try {
        const response = await fetch(
          `https://api.investdpaisa.com/api/bidTables/results?biddingScheme=${scheme}`
        );
        if (!response.ok) {
          setMessage(`Failed to fetch results for ${scheme}`);
          return;
        }
        const data = await response.json();
        console.log(data.bidTables);

        // Sort results by expire to get the latest result
        const latestResult = Array.isArray(data.bidTables)
          ? data.bidTables.sort(
              (a, b) => new Date(b.expire) - new Date(a.expire)
            )[0] // Sort based on 'expire'
          : null;

        setResults((prevResults) => ({
          ...prevResults,
          [scheme]: latestResult ? [latestResult] : [], // Only store the latest result for each scheme
        }));
      } catch (err) {
        setMessage("Error fetching results for " + scheme + ": " + err.message);
      }
    };

    ["120mins", "2mins", "12hrs", "1mins" , "1minsCoin"].forEach(fetchResults);
  }, []);

  const calculateTimeLeft = (expire) => {
    const now = new Date();
    now.setHours(now.getHours() + 5);
    now.setMinutes(now.getMinutes() + 30);

    const expiryDate = new Date(expire);
    const diff = expiryDate - now;

    if (diff <= 0) {
      return "Expired";
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="p-2 px-2 mb-20">
      {/* Section 1: Date, Time, and Day */}
      <section className="bg-gradient-to-r from-red-900 via-orange-500 to-red-600 text-white flex flex-col justify-center items-center text-left py-6 px-8 rounded-lg shadow-2xl transform transition-all duration-300 hover:scale-105 hover:shadow-xl mb-2">
        <p className="text-md font-semibold tracking-wide text-shadow-lg">
          {dateTime.day}, {dateTime.date} - {dateTime.time}
        </p>
      </section>

      {/* Section: Messages */}

      {/* Section: Results for different bidding schemes */}
      <section className="bg-[#2A3335] text-white py-6 px-4 rounded-md shadow-lg backdrop-blur-md bg-opacity-40">
        <div className="flex overflow-hidden w-full">
          {/* Wrapper for the items */}
          <div className="flex animate-marquee space-x-4">
            {/* Original items */}
            {[
              { title: "Select to Win!", key: "120mins", color: "bg-blue-200" },
              { title: "Roll Your Luck!", key: "2mins", color: "bg-green-200" },
              {
                title: "Choose your Fate!",
                key: "12hrs",
                color: "bg-yellow-200",
              },
              { title: "Lucky Pick!", key: "1mins", color: "bg-pink-200" },
              { title: "Coin Quest!", key: "1minsCoin", color: "bg-sky-200" },
            ].map((item) => (
              <div
                key={item.key}
                className={`${item.color} p-6 rounded-lg shadow-xl flex flex-col justify-between min-w-[220px] transform transition-all duration-300 hover:scale-105 backdrop-blur-md bg-opacity-50`}
              >
                <h3 className="text-sm font-semibold text-center text-yellow-300">
                  {item.title}
                </h3>
                <ul className="text-xs text-yellow-300 mt-2 space-y-1">
                  {Array.isArray(results[item.key]) &&
                  results[item.key].length === 0 ? (
                    <p className="text-center text-xs text-yellow-300">
                      No results available
                    </p>
                  ) : (
                    results[item.key].map((result, index) => (
                      <li key={index} className="text-center">
                        <p>
                          <strong className="text-yellow-300">Winner:</strong>{" "}
                          {item.key === "1mins"
                            ? {
                                1: "Animals (जानवर)",
                                2: "Plants (पौधे)",
                                3: "Numbers (संख्या)",
                                4: "Alphabets (अक्षर)",
                              }[
                                result.result &&
                                result.result.match(/"winnerNumber":(\d+)/)
                                  ? parseInt(
                                      result.result.match(
                                        /"winnerNumber":(\d+)/
                                      )[1]
                                    )
                                  : "N/A"
                              ] || "N/A"
                            : result.result
                            ? result.result.match(/"winnerNumber":(\d+)/)
                              ? result.result.match(/"winnerNumber":(\d+)/)[1]
                              : "N/A"
                            : "N/A"}
                        </p>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            ))}
            {/* Duplicated items to make the loop seamless */}
            {[
              { title: "Select to Win!", key: "120mins", color: "bg-blue-200" },
              { title: "Roll Your Luck!", key: "2mins", color: "bg-green-200" },
              {
                title: "Choose your Fate!",
                key: "12hrs",
                color: "bg-yellow-200",
              },
              { title: "Lucky Pick!", key: "1mins", color: "bg-pink-200" },
              { title: "Coin Quest!", key: "1minsCoin", color: "bg-sky-200" },
            ].map((item) => (
              <div
                key={`duplicate-${item.key}`}
                className={`${item.color} p-6 rounded-lg shadow-xl flex flex-col justify-between min-w-[220px] transform transition-all duration-300 hover:scale-105 backdrop-blur-md bg-opacity-50`}
              >
                <h3 className="text-sm font-semibold text-center text-yellow-300">
                  {item.title}
                </h3>
                <ul className="text-xs text-yellow-300 mt-2 space-y-1">
                  {Array.isArray(results[item.key]) &&
                  results[item.key].length === 0 ? (
                    <p className="text-center text-xs text-yellow-300">
                      No results available
                    </p>
                  ) : (
                    results[item.key].map((result, index) => (
                      <li key={index} className="text-center">
                        <p>
                          <strong className="text-yellow-300">Winner:</strong>{" "}
                          {item.key === "1mins"
                            ? {
                                1: "Animals (जानवर)",
                                2: "Plants (पौधे)",
                                3: "Numbers (संख्या)",
                                4: "Alphabets (अक्षर)",
                              }[
                                result.result &&
                                result.result.match(/"winnerNumber":(\d+)/)
                                  ? parseInt(
                                      result.result.match(
                                        /"winnerNumber":(\d+)/
                                      )[1]
                                    )
                                  : "N/A"
                              ] || "N/A"
                            : result.result
                            ? result.result.match(/"winnerNumber":(\d+)/)
                              ? result.result.match(/"winnerNumber":(\d+)/)[1]
                              : "N/A"
                            : "N/A"}
                        </p>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Section: Bid Tables */}
      <section className=" text-white rounded-md shadow-md mt-4">
        <h2 className="bg-customgrey text-transparent bg-clip-text text-2xl font-extrabold text-center py-4 rounded-lg shadow-xl mb-4 shadow-[0_4px_20px_rgba(255,215,0,0.3)] transform hover:scale-105 transition-all duration-300 bg-gradient-to-r from-[#DAA520] via-[#FFD700] to-[#FFD700]">
          Unleash Your Inner Hero
        </h2>

        <div className="grid grid-cols-2 items-center mt-4 gap-4">
          {Object.keys(bidTables).length === 0 ? (
            <p className="text-gray-300">Loading bid tables...</p>
          ) : (
            Object.entries(bidTables).map(([scheme, tables]) => {
              // Assign different background images based on the scheme
              const backgroundImage =
                scheme === "12hrs"
                  ? "url('https://ik.imagekit.io/7uve7qsipm/23144.jpg?updatedAt=1733863525607')"
                  : scheme === "120mins"
                  ? "url('https://ik.imagekit.io/7uve7qsipm/krissia-cruz-FwPzGn59wNs-unsplash.jpg?updatedAt=1733863526256')"
                  : scheme === "2mins"
                  ? "url('https://ik.imagekit.io/7uve7qsipm/4376536.jpg?updatedAt=1733863472317')"
                  : scheme === "1mins"
                  ? "url('https://ik.imagekit.io/7uve7qsipm/2282.jpg?updatedAt=1733863505029')"
                  : "url('https://ik.imagekit.io/7uve7qsipm/oi.png?updatedAt=1734044062059')";

              return (
                <div
                  key={scheme}
                  className="w-full bg-cover bg-center relative rounded-lg overflow-hidden"
                  style={{ backgroundImage }}
                >
                  <div className="absolute inset-0 bg-black opacity-80"></div>
                  <div className="relative grid grid-col sm:grid-cols-2 lg:grid-cols-3 gap-2 p-2">
                    {tables.length > 0 ? (
                      tables.map((table, index) => {
                        const timeLeft = calculateTimeLeft(table.expire);
                        const linkPath =
                          scheme === "12hrs"
                            ? "/bidding2"
                            : scheme === "120mins"
                            ? "/bidding1"
                            : scheme === "2mins"
                            ? "/bidding3"
                            : scheme === "1mins"
                            ? "/bidding4"
                            : scheme === "1minsCoin"
                            ? "/bidding5"
                            : "/";

                        const now = new Date();
                        now.setHours(now.getHours() + 5);
                        now.setMinutes(now.getMinutes() + 30);

                        const startTime = new Date(table.start);
                        if (startTime > now) {
                          return null;
                        }

                        if (timeLeft === "Expired") {
                          return null;
                        }

                        return (
                          <Link
                            to={`${linkPath}/${table._id}`}
                            key={index}
                            className="w-[163px] h-[150px] p-4  rounded-lg shadow-lg shadow-[0px_4px_20px_rgba(218,165,32,0.7)] transform hover:scale-105 transition-all duration-300"
                          >
                            <div className="bg-gradient-to-r from-[#DAA520] via-[#FFD700] to-[#FFD700] text-transparent bg-clip-text text-2xl text-center py-2">
                              <p className="text-xl mb-4 text-white">
                                {scheme === "12hrs"
                                  ? "Choose your Fate!"
                                  : scheme === "120mins"
                                  ? "Select To Win!"
                                  : scheme === "2mins"
                                  ? "Roll Your Luck!"
                                  : scheme === "1mins"
                                  ? "Lucky Pick!"
                                  : scheme === "1minsCoin"
                                  ? "Coin Quest!"
                                  : "General Bidding Table"}
                              </p>

                              <p className="text-red-500 text-sm">{timeLeft}</p>
                            </div>
                          </Link>
                        );
                      })
                    ) : (
                      <div className="w-full flex justify-center items-center h-[150px] bg-gray-800 rounded-lg shadow-lg">
                        <p className="text-white">No tables available</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </section>
    </div>
  );
};

export default Home;
