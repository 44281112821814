import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [consent, setConsent] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!consent) {
      setError("Please accept the Privacy Policy and Terms.");
      return;
    }

    if (username && password) {
      try {
        const response = await axios.post(
          "https://api.investdpaisa.com/api/users/login",
          {
            username,
            password,
          }
        );

        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          alert("Login Successful!");
          window.location.href = "/"; // Adjust the route as needed
        }
      } catch (error) {
        setError(
          error.response?.data?.message ||
            "Invalid credentials or server error."
        );
        console.error(error);
      }
    } else {
      setError("Please fill all fields");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-4 bg-gradient-to-br from-gray-900 via-customgrey to-gray-900">
      <div className="bg-customgrey shadow-2xl rounded-lg p-8 max-w-md w-full text-white">
        {/* App Logo and Name */}
        <div className="flex flex-row justify-center items-center space-x-2">
          <div className="flex flex-col items-center">
            <h1
              className="text-6xl font-bold text-yellow-300 m-0 p-0"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
            >
              SAM
            </h1>
            <p
              className="text-4xl text-white m-0 p-0 -mt-1"
              style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}
            >
              GAMES
            </p>
          </div>
        </div>

        {/* Login Form */}
        <h2 className="text-2xl font-bold text-center text-yellow-300 my-6">
          Login Page
        </h2>
        <div className="flex flex-col gap-4">
          {error && (
            <div className="text-red-400 text-center mb-4 font-semibold">
              {error}
            </div>
          )}
          <input
            type="text"
            placeholder="Enter Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
          />
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
          />
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              id="consent"
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
              className="w-5 h-5 text-yellow-300 focus:ring-yellow-300 border-gray-300 rounded"
            />
            <label htmlFor="consent" className="text-gray-300">
              I agree to the{" "}
              <Link
                to="/privacypolicy"
                rel="noopener noreferrer"
                className="text-yellow-300  hover:text-yellow-400"
              >
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link
                to="/termsandconditions"
                rel="noopener noreferrer"
                className="text-yellow-300  hover:text-yellow-400"
              >
                Terms and Conditions
              </Link>
              .
            </label>
          </div>
          <button
            onClick={handleLogin}
            disabled={!consent}
            className={`w-full py-3 rounded-lg font-bold text-lg tracking-wide transition ${
              consent
                ? "bg-yellow-300 hover:bg-yellow-400 text-black"
                : "bg-gray-600 cursor-not-allowed text-gray-400"
            }`}
          >
            Login
          </button>
        </div>
        <p className="text-center text-gray-400 text-sm mt-2">
          Forgot your password?{" "}
          <Link to="/forgotpassword">
            <span className="text-yellow-300  hover:underline">
              Reset it here
            </span>
          </Link>{" "}
          or{" "}
          <Link to="/createAccount">
            <span className="text-yellow-300  hover:underline">
              sign up here
            </span>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Login;
