import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Games = () => {
  const [bidTables, setBidTables] = useState({});
  const [message, setMessage] = useState("");

  // Function to calculate time left
  const calculateTimeLeft = (expire) => {
    const now = new Date();
    now.setHours(now.getHours() + 5);
    now.setMinutes(now.getMinutes() + 30);

    const expiryDate = new Date(expire);
    const diff = expiryDate - now;

    if (diff <= 0) {
      return "Expired";
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const fetchBidTables = async () => {
      try {
        const response = await fetch("https://api.investdpaisa.com/api/bidTables/");
        if (!response.ok) {
          setMessage("Failed to fetch bid tables");
          return;
        }
        const data = await response.json();
        setBidTables(data);
      } catch (err) {
        setMessage("Error fetching bid tables: " + err.message);
      }
    };

    fetchBidTables();

    // Set interval to update time every second
    const intervalId = setInterval(() => {
      setBidTables((prevTables) => {
        const updatedTables = { ...prevTables };
        for (const scheme in updatedTables) {
          updatedTables[scheme] = updatedTables[scheme].map((table) => ({
            ...table,
            timeLeft: calculateTimeLeft(table.expire),
          }));
        }
        return updatedTables;
      });
    }, 1000);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Function to get the background image for each game type
  const getBackgroundImage = (biddingScheme) => {
    switch (biddingScheme) {
      case "12hrs":
        return "url('https://ik.imagekit.io/7uve7qsipm/krissia-cruz-FwPzGn59wNs-unsplash.jpg?updatedAt=1733863526256')";
      case "120mins":
        return "url('https://ik.imagekit.io/7uve7qsipm/23144.jpg?updatedAt=1733863525607')";
      case "2mins":
        return "url('https://ik.imagekit.io/7uve7qsipm/4376536.jpg?updatedAt=1733863472317')";
      case "1mins":
        return "url('https://ik.imagekit.io/7uve7qsipm/2282.jpg?updatedAt=1733863505029')";
        case "1minsCoin":
          return "url('https://ik.imagekit.io/7uve7qsipm/oi.png?updatedAt=1734044062059')";
      default:
        return "url('/images/general-bidding.jpg')";
    }
  };

  return (
    <section className="bg-graycustom text-white shadow-xl mb-20 pt-10">
      <div className="flex flex-col w-full justify-start gap-2 px-4">
        {Object.keys(bidTables).length === 0 ? (
          <p className="text-lg text-center">
            {message || "Loading bid tables..."}
          </p>
        ) : (
          Object.entries(bidTables).map(([scheme, tables]) => (
            <div key={scheme} className="flex flex-col items-center w-full">
              <div className="flex flex-col justify-start gap-12 mb-4 w-full">
                {tables.map((table, index) => {
                  const timeLeft = calculateTimeLeft(table.expire);

                  const linkPath =
                    scheme === "12hrs"
                      ? "/bidding2"
                      : scheme === "120mins"
                      ? "/bidding1"
                      : scheme === "2mins"
                      ? "/bidding3"
                      : scheme === "1mins"
                      ? "/bidding4"
                      : scheme === "1minsCoin"
                      ? "/bidding5"
                      : "/";

                  const now = new Date();
                  now.setHours(now.getHours() + 5);
                  now.setMinutes(now.getMinutes() + 30);

                  const startTime = new Date(table.start);
                  if (startTime > now || timeLeft === "Expired") {
                    return null;
                  }

                  return (
                    <Link
                      to={`${linkPath}/${table._id}`}
                      key={index}
                      className="w-full" // Ensure each card takes up the full row width
                    >
                      <div
                        className="bg-cover bg-center rounded-lg shadow-lg text-start transform hover:scale-105 transition duration-300 ease-in-out relative"
                        style={{
                          backgroundImage: getBackgroundImage(scheme),
                          height: "150px", // Set a fixed height for a compact card
                          objectFit: "cover", // Ensures the background image covers the space
                        }}
                      >
                        <div className="backdrop-blur-[1px] min-w-full bg-black/80  shadow-[0px_4px_15px_rgba(218,165,32,0.7)]  p-4 rounded-lg absolute inset-0 flex flex-col justify-between">
                          <h3 className="text-2xl text-white font-bold mb-4">
                            {scheme === "12hrs"
                              ? "Choose your Fate!"
                              : scheme === "120mins"
                              ? "Select To Win!"
                              : scheme === "2mins"
                              ? "Roll Your Luck!"
                              : scheme === "1mins"
                              ? "Lucky Pick!"
                              : scheme === "1minsCoin"
                              ? "Coin Quest!"
                              : "General Bidding Table"}
                          </h3>

                          <p className="text-lg text-black bg-white/80 mt-10 rounded-lg px-2">
                            <strong>Time Left:</strong> {timeLeft}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default Games;
