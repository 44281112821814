import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Corrected import statement
import { Link } from "react-router-dom";

const Navbar = () => {
  const [walletBalance, setWalletBalance] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    // Fetch token from localStorage
    const token = localStorage.getItem("token");

    if (token) {
      try {
        // Decode the token to get the userId
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId; // Assuming 'userId' is in the token

        // Fetch wallet balance using the userId
        if (userId) {
          fetchWalletBalance(userId);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const fetchWalletBalance = async (userId) => {
    try {
      // Make an API call to fetch the wallet balance using the userId
      const response = await fetch(`https://api.investdpaisa.com/api/users/${userId}`);
      if (response.ok) {
        const data = await response.json();
        setWalletBalance(data.walletBalance || 0);
        setUsername(data.username);
      } else {
        console.error("Failed to fetch wallet balance");
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  return (
    <div className="bg-customgrey text-white text-center py-4 font-bold flex justify-between items-center px-4 shadow-lg shadow-yellow-800/80">
      <div className="flex flex-row justify-center items-center space-x-2">
        <div className="flex flex-row justify-center items-center space-x-2">
          <div className="flex flex-col items-center">
            <h1
              className="text-2xl font-bold text-yellow-300 m-0 p-0"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
            >
              SAM
            </h1>
            <p
              className="text-xs text-white m-0 p-0 -mt-1"
              style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}
            >
              GAMES
            </p>
          </div>
        </div>
      </div>

      <div className="text-sm">
        <Link to="/account">
          <div className="flex items-center space-x-2">
            {/* Golden Wallet Icon */}
            <i className="fa-solid fa-wallet text-yellow-500 text-lg"></i>

            {/* Funds */}
            <span>
              {walletBalance !== null
                ? `${walletBalance.toFixed(2)}`
                : "Loading..."}
            </span>

            {/* Circle with First Letter of Username */}
            <div className="w-8 h-8 rounded-full bg-gray-500 flex items-center justify-center text-white text-sm">
              {username ? username.charAt(0).toUpperCase() : ""}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
