import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { FaPlusCircle, FaArrowCircleDown } from "react-icons/fa"; // Import icons
import { FiSettings } from "react-icons/fi"; // Import settings icon
const Account = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [filterCount, setFilterCount] = useState(10);
  const history = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async (userId) => {
      try {
        const response = await axios.get(
          `https://api.investdpaisa.com/api/users/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch user details. कृपया पुनः प्रयास करें");
        setLoading(false);
      }
    };

    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.userId) {
          fetchUserDetails(decodedToken.userId);
        } else {
          setError("Invalid token structure. अमान्य टोकन संरचना");
          setLoading(false);
        }
      } catch (error) {
        setError("Failed to decode the token. टोकन डिकोड करने में विफल");
        setLoading(false);
      }
    } else {
      setError(
        "No token found, please log in. कोई टोकन नहीं मिला, कृपया लॉगिन करें"
      );
      setLoading(false);
    }
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem("token");
    alert("Logout Sucessfully");
    window.location.reload();
  };
  // Listen for the window close or refresh event

  const handleResetPassword = () => {
    history("/resetPassword");
  };

  const sortedTransactions = user
    ? [...user.transactions].sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  const filteredTransactions = sortedTransactions.slice(0, filterCount);

  if (loading) {
    return <div className="text-center py-20">Loading... लोड हो रहा है...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center py-20">{error}</div>;
  }
  const handleAddFunds = () => {
    // Logic for adding funds
    history("/addPayment");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-gray-900 via-customgrey to-gray-900 ">
      <div className="bg-customgrey text-white shadow-xl p-4 w-full max-w-4xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-semibold text-yellow-300">
            Account Details
          </h2>

          <div className="flex items-center space-x-3">
            <button
              onClick={handleResetPassword}
              className="bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 transition transform hover:scale-105"
            >
              <FiSettings size={20} />
            </button>
            <button
              onClick={handleLogout}
              className="bg-red-600 text-white p-3 rounded-full hover:bg-red-700 transition transform hover:scale-105"
            >
              <AiOutlineLogout size={20} />
            </button>
          </div>
        </div>

        <div className="flex flex-row justify-around mb-6">
          <Link
            to="/addfunds"
            className="flex items-center bg-gradient-to-r from-blue-500 to-teal-500 text-white text-sm font-semibold py-2 px-8 rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300 ease-in-out"
          >
            <FaPlusCircle className="mr-2 " />
            Add Points
          </Link>

          <Link
            to="/withdrawfunds"
            className="flex items-center bg-gradient-to-r from-red-500 to-orange-500 text-white text-sm font-semibold py-2 px-6 rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300 ease-in-out"
          >
            <FaArrowCircleDown className="mr-2" />
            Withdraw Points
          </Link>
        </div>

        {user ? (
          <div>
            <p className="text-xl font-medium text-gray-300">
              Hi, {user.username}
            </p>
            <p className="text-2xl font-bold text-yellow-300 mt-4">
              <strong>Wallet Balance:</strong> {user.walletBalance}
            </p>

            {/* Filter Dropdown */}
            <div className="mb-4 mt-6 flex items-center space-x-2">
              <label
                htmlFor="transactionCount"
                className="text-lg text-gray-300"
              >
                Show Last:
              </label>
              <select
                id="transactionCount"
                value={filterCount}
                onChange={(e) => setFilterCount(Number(e.target.value))}
                className="p-2 border border-gray-600 rounded-md bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
              </select>
            </div>

            <div className="overflow-x-auto mt-4">
              <table className="min-w-full table-auto border-separate ">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left font-semibold text-yellow-300">
                      Amount
                    </th>
                    <th className="px-6 py-3 text-left font-semibold text-yellow-300">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left font-semibold text-yellow-300">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransactions.map((transaction, index) => (
                    <tr
                      key={index}
                      className={
                        transaction.type === "Credit"
                          ? "bg-gray-800"
                          : "bg-gray-900"
                      }
                    >
                      <td className="px-6 py-3 text-yellow-300">
                        {transaction.type === "Credit" ? "+" : "-"}
                        {transaction.amount}
                      </td>
                      <td className="px-6 py-3 text-gray-300">
                        {new Date(transaction.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-3 text-gray-300">
                        {transaction.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className="text-gray-300 text-center py-4">
            No user data available | कोई उपयोगकर्ता डेटा उपलब्ध नहीं है
          </p>
        )}
      </div>
    </div>
  );
};

export default Account;
