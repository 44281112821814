import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faGamepad,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="bg-customgrey text-white py-4 text-sm font-medium bottom-0 fixed w-full">
      <div className="flex justify-between items-center relative px-8">
        {/* Home Tab */}

        <div className="flex flex-row gap-8">
          <Link
            to="/"
            className="flex flex-col items-center focus:outline-none text-gold hover:text-gold-light"
          >
            <FontAwesomeIcon icon={faHome} size="lg" />
            <span className="text-yellow-300 text-xs mt-1">Home</span>
          </Link>

          {/* Game Tab */}
          <Link
            to="/game"
            className="flex flex-col items-center focus:outline-none text-gold hover:text-gold-light"
          >
            <FontAwesomeIcon icon={faGamepad} size="lg" />
            <span className="text-yellow-300 text-xs mt-1">Games</span>
          </Link>
        </div>
        {/* Spin Game Button */}
        <div className="absolute flex justify-center items-center top-[-20px] left-1/2 transform -translate-x-1/2">
          <Link
            to="/spin"
            className="w-16 h-16 bg-yellow-500 text-white rounded-full flex justify-center items-center shadow-lg hover:bg-yellow-600 focus:outline-none"
          >
            <span className="text-lg font-bold">Spin</span>
          </Link>
        </div>

        {/* Profile Tab */}
        <div className="flex flex-row gap-8">
          <Link
            to="/account"
            className="flex flex-col items-center focus:outline-none text-gold hover:text-gold-light"
          >
            <FontAwesomeIcon icon={faUser} size="lg" />
            <span className="text-yellow-300 text-xs mt-1">Profile</span>
          </Link>

          {/* Refresh Tab */}
          <div
            onClick={() => window.location.reload()}
            className="flex flex-col items-center cursor-pointer focus:outline-none text-gold hover:text-gold-light"
          >
            <FontAwesomeIcon icon={faSync} size="lg" />
            <span className="text-yellow-300 text-xs mt-1">Refresh</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
