import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode
import CountdownPopup from "../components/CountdownPopup";
function Bidding5() {
    const { tableId } = useParams();
    const navigate = useNavigate();
    const [isExpired, setIsExpired] = useState(false); // Track if the time is expired

    const [timeLeft, setTimeLeft] = useState(null);
    const [tableDetails, setTableDetails] = useState(null); // State to store table details
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [betAmount, setBetAmount] = useState("");
    const [recentResults, setRecentResults] = useState([]); // State to store recent results
    const [userData, setUserData] = useState(null); // User data from decoded token
    const [error, setError] = useState(null);
    const biddingScheme = "1minsCoin";
    const categories = [
        {

            name: "Head",
            image:
                "https://ik.imagekit.io/7uve7qsipm/Head-removebg-preview.png?updatedAt=1734020375221"
        },
        {

            name: "Tail",
            image:
                "https://ik.imagekit.io/7uve7qsipm/tail-removebg-preview.png?updatedAt=1734020547923"
        },
    ];

    const categoryMapping = {
        "Head": 1,
        "Tail": 2,
    };

    useEffect(() => {
        const fetchRecentResults = async () => {
            try {
                const response = await fetch(
                    `https://api.investdpaisa.com/api/bidTables/results?biddingScheme=${biddingScheme}`
                );
                if (!response.ok) {
                    throw new Error(`Failed to fetch recent results for table ID: ${biddingScheme}`);
                }
                const data = await response.json();
                console.log(data.bidTables);
                setRecentResults(data.bidTables);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchRecentResults(); // Call function to fetch results
        if (isExpired) {
            fetchRecentResults(); // Fetch results when time is expired
        }


    }, [isExpired]);

    // Fetch user data from the token
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            setError("User not logged in. Please log in.");
            return;
        }

        try {
            const decodedToken = jwtDecode(token);
            if (decodedToken && decodedToken.userId) {
                // Fetch user details using the decoded userId
                const fetchUserData = async () => {
                    const response = await axios.get(
                        `https://api.investdpaisa.com/api/users/${decodedToken.userId}`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );
                    setUserData(response.data);
                };
                fetchUserData();
            }
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    }, []);



    // Fetch table details based on tableId
    useEffect(() => {
        const fetchTableDetails = async () => {
            try {
                if (!tableId) {
                    throw new Error("Table ID is missing in the query parameters.");
                }
                const response = await fetch(
                    `https://api.investdpaisa.com/api/bidTables/${tableId}`
                );
                if (!response.ok) {
                    throw new Error(`Failed to fetch details for table ID: ${tableId}`);
                }
                const data = await response.json();
                setTableDetails(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchTableDetails();
    }, [tableId]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setError(null);
    };

    const handlePlaceBet = async () => {
        if (!selectedCategory) {
            setError(
                "कृपया पहले एक श्रेणी चुनें। (Please select a category before placing a bet.)"
            );
            return;
        }
        if (!betAmount || isNaN(betAmount) || betAmount <= 0) {
            setError(
                "कृपया एक मान्य दांव राशि दर्ज करें। (Please enter a valid bet amount.)"
            );
            return;
        }

        if (!userData) {
            setError("User data not found. Please log in again.");
            return;
        }

        const betValue = parseFloat(betAmount);
        const winAmount = betValue * 1.8; // Calculate win amount
        const updatedWalletBalance = userData.walletBalance - betValue;

        if (updatedWalletBalance < 0) {
            setError("Insufficient balance to place the bet.");
            return;
        }

        try {
            // Place the bet
            const response = await axios.post(
                `https://api.investdpaisa.com/api/bidtables/${tableId}/bid`,
                {
                    bidderId: userData._id,
                    number: categoryMapping[selectedCategory],
                    winAmount,
                    bidAmount: betValue,
                }
            );
            // Generate a random order ID
            const orderId = `ORD-${Math.floor(
                Math.random() * 1000000
            )}-${Date.now()}`;
            // Create a new transaction record
            const newTransaction = {
                orderId,
                amount: betValue,
                type: "debit", // Debit transaction for placing a bid
                date: new Date().toISOString(),
                description: `You placed a bet on ${selectedCategory} of amount ${betValue} in bid table(${tableDetails}) at ${new Date().toLocaleTimeString()} and Your orderId is ${orderId}.`,
            };
            if (response.status === 200) {
                // Update user wallet
                const updatedUserData = {
                    walletBalance: updatedWalletBalance,
                    transactions: [...userData.transactions, newTransaction], // Add new transaction to existing history
                };

                await axios.put(
                    `https://api.investdpaisa.com/api/users/${userData._id}`,
                    updatedUserData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );

                alert(`Bet placed successfully! Win Amount: ${winAmount}`);
                window.location.reload();
            }
        } catch (err) {
            console.error("Error placing bid:", err);
            setError("Failed to place the bid. Please try again later.");
        }
    };

    // Function to calculate time left
    const calculateTimeLeft = (expire) => {
        const now = new Date();
        now.setHours(now.getHours() + 5); // Adjust for timezone (if required)
        now.setMinutes(now.getMinutes() + 30); // Adjust for timezone (if required)

        const expiryDate = new Date(expire);
        const diff = expiryDate - now;

        if (diff > 0) {
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
            setIsExpired(false); // Time has not expired
        } else {
            setTimeLeft("Expired");
            setIsExpired(true); // Time has expired

        }
    };

    // Set an interval to update the time left every second
    useEffect(() => {
        let timer;
        if (tableDetails?.expire) {
            timer = setInterval(() => {
                calculateTimeLeft(new Date(tableDetails.expire));
            }, 1000);
        }

        return () => clearInterval(timer); // Cleanup interval on unmount
    }, [tableDetails]);

    return (
        <div className="p-4 pb-20">
            <h1 className="text-2xl font-bold text-center mb-2 text-yellow-300">Coin Quest!</h1>
            <h2 className="text-xs font-semibold text-center my-2 text-white">
            कृपया हेड या टेल चुनें में से एक चुनने (Choose any between head and tail)
            </h2>
            <h2 className="text-center my-2 text-yellow-300">🔥Earn 1.8X Rewards on Every Play!🔥</h2>
            {!tableDetails ? (
                <p className="text-center text-gray-500">Loading table details...</p>
            ) : (
                <>
                    {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                    {/* Display Table Details */}
                    <div className="mb-4 text-center text-sm bg-gray-100 p-4 rounded-md shadow-md">
                        <p className="text-red-500">
                            <strong>Time Left:</strong> {timeLeft}
                        </p>
                    </div>
                    <main className="flex flex-col items-center space-y-4">
                        <div className="flex space-x-6">
                            {categories.map((category) => (
                                <div
                                    key={category.name}
                                    onClick={() => {
                                        if (timeLeft !== "Expired") {
                                            handleCategoryClick(category.name);
                                        }
                                    }}
                                    className={`w-40 h-40 p-0 text-center rounded-full shadow-md transition border 
                                            ${selectedCategory === category.name
                                            ? "bg-blue-200 border-blue-400"
                                            : "bg-gray-100 border-gray-300"
                                        }
                                            ${timeLeft === "Expired"
                                            ? "cursor-not-allowed opacity-50"
                                            : "cursor-pointer"
                                        }
                                            hover:bg-blue-100`}
                                >
                                    <img
                                        src={category.image}
                                        alt={category.name}
                                        className="w-40 h-40 object-contain"
                                    />
                                </div>
                            ))}
                        </div>
                    </main>

                    <div>
                        {timeLeft === "Expired" ? (
                            <CountdownPopup
                                result={recentResults[recentResults.length - 1]?.result}
                            />
                        ) : (
                            // Replace this with your actual component
                            <p></p> // Optional: replace with another component or content
                        )}
                    </div>
                    <div className="flex flex-col items-center mt-6 space-y-4">
                        <input
                            type="number"
                            value={betAmount}
                            onChange={(e) => setBetAmount(e.target.value)}
                            placeholder="Enter your bet amount (अपनी दांव राशि दर्ज करें)"
                            className="w-full px-4 py-2 rounded-lg border border-yellow-500 bg-[#374042] text-yellow-300"
                        />
                        <div className="flex space-x-4">
                            {[50, 100, 500, 1000, 2000].map((betAmount) => (
                                <button
                                    key={betAmount}
                                    onClick={() => setBetAmount(betAmount)}
                                    className="px-4 py-2 text-white font-semibold bg-yellow-500 rounded-lg"
                                >
                                    {betAmount}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="text-center mt-8">
                        <button
                            onClick={handlePlaceBet}
                            className="bg-yellow-500 border-2 p-2 w-full border-white  flex items-center rounded-full justify-center text-xl text-white font-bold"
                        >
                            Place Bet (दांव लगाएं)
                        </button>
                    </div>
                </>
            )}
            <div className="mt-8 bg-gray-200 p-4 rounded-md shadow-md">
                {/* Display most recent result */}
                {recentResults.length > 0 && (
                    <div className="mb-4 bg-white p-4 rounded-md shadow-md">
                        <p className="text-center text-lg font-semibold text-gray-800">
                            <strong>Most Recent Result</strong>
                        </p>
                    </div>
                )}

                {/* Display last 10 results */}
                <div className="bg-[#374042] rounded-lg p-4 text-yellow-300 flex flex-wrap grid grid-cols-5 gap-2">
                    {recentResults
                        .slice() // Create a shallow copy to avoid modifying the original array
                        .slice(0, 10) // Limit to the latest 10 results
                        .map((result, index) => {
                            // Parse JSON from result.result
                            let winnerNumber = null;
                            try {
                                const parsedData = JSON.parse(result.result);
                                winnerNumber = parsedData.winnerNumber;
                            } catch (error) {
                                console.error("Error parsing JSON:", error, result.result);
                            }

                            // Map winnerNumber to category name
                            const categoryMapping = {
                                "H": 1,
                                "T": 2,
                            };

                            // Reverse the mapping to get the category name from winnerNumber
                            const winnerCategory = Object.keys(categoryMapping).find(
                                (key) => categoryMapping[key] === winnerNumber
                            );

                            return (
                                <div
                                    key={index}
                                    className={`w-12 h-12 bg-yellow-500 border-8 border-white  flex items-center rounded-full justify-center text-xl text-white font-bold`}
                                >
                                    {winnerCategory || "N/A"}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default Bidding5;
