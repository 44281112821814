import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateAccount = () => {
  const [formData, setFormData] = useState({
    username: "",
    mobileNumber: "",
    email: "",
    upiId: "",
    password: "",
    confirmPassword: "",
    securityQuestion: "", // New field for security question
    securityAnswer: "",   // New field for security answer
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate passwords match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Check if a security question and answer are provided
    if (!formData.securityQuestion || !formData.securityAnswer) {
      setError("Please answer the security question.");
      return;
    }

    try {
      // Send form data to the API
      const response = await axios.post(
        "https://api.investdpaisa.com/api/users/create-client",
        {
          username: formData.username,
          mobileNumber: formData.mobileNumber,
          email: formData.email,
          upiId: formData.upiId,
          password: formData.password,
          securityQuestion: formData.securityQuestion,
          securityAnswer: formData.securityAnswer, // Send security answer
        }
      );

      // Handle success
      setSuccess("Account created successfully!");
      navigate("/login");
      setError("");
      setFormData({
        username: "",
        mobileNumber: "",
        email: "",
        upiId: "",
        password: "",
        confirmPassword: "",
        securityQuestion: "",
        securityAnswer: "",
      });
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
      setSuccess("");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-gray-900 via-customgrey to-gray-900 px-8">
      <div className="w-full max-w-md bg-customgrey text-white rounded-2xl shadow-xl p-6">
        <h2 className="text-4xl font-extrabold text-center text-yellow-300 mb-6">
          Create Account
        </h2>

        {/* Success Popup */}
        {success && (
          <div className="bg-green-100 text-green-700 px-4 py-3 rounded mb-4">
            {success}
          </div>
        )}

        {/* Error Popup */}
        {error && (
          <div className="bg-red-100 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-yellow-300 font-medium mb-1"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              placeholder="Enter your username"
              value={formData.username}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="mobileNumber"
              className="block text-yellow-300 font-medium mb-1"
            >
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumber"
              placeholder="Enter your mobile number"
              value={formData.mobileNumber}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-yellow-300 font-medium mb-1"
            >
              Email <span className="text-gray-400">(Optional)</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="upiId"
              className="block text-yellow-300 font-medium mb-1"
            >
              UPI ID
            </label>
            <input
              type="text"
              id="upiId"
              placeholder="Enter your UPI ID"
              value={formData.upiId}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-yellow-300 font-medium mb-1"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="confirmPassword"
              className="block text-yellow-300 font-medium mb-1"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>

          {/* Security Question Section */}
          <div className="mb-4">
            <label
              htmlFor="securityQuestion"
              className="block text-yellow-300 font-medium mb-1"
            >
              Security Question
            </label>
            <select
              id="securityQuestion"
              value={formData.securityQuestion}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            >
              <option value="">Select a security question</option>
              <option value="yourFirstPet">What was the name of your first pet?</option>
              <option value="yourMotherMaidenName">What is your mother's maiden name?</option>
              <option value="yourFavoriteFood">What is your favorite food?</option>
              <option value="yourBirthPlace">What is the place of your birth?</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="securityAnswer"
              className="block text-yellow-300 font-medium mb-1"
            >
              Security Answer
            </label>
            <input
              type="text"
              id="securityAnswer"
              placeholder="Enter your answer"
              value={formData.securityAnswer}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-yellow-300 text-black font-bold rounded-lg hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
